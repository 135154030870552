
import isEqual from 'lodash/isEqual'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { SocketModule } from '@/modules/socket/store'
import { VersionModule } from '@/modules/version/store'

import CreateDialog from '../components/create-dialog.vue'
import DeleteDialog from '../components/delete-dialog.vue'
import MemberList from '../components/member-list.vue'
import ObjectGrid from '../components/object-grid.vue'
import { IObjectGridItem } from '../components/object-grid-item.vue'
import * as analytics from '../helpers/analytics'
import { TeamModule } from '../store'

@Component({
  components: {
    CreateDialog,
    DeleteDialog,
    MemberList,
    Menu,
    ObjectGrid,
    OrganizationUpgradeMenu,
    TeamMemberTable: () => import('@/modules/team/components/member-table.vue'),
    TeamSelectionBar: () => import('@/modules/team/components/selectionbar/index.vue')
  },
  name: 'Team'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  leftContainerScroll = 0
  rightContainerScroll = 0

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return (this.$params.landscapeId || this.currentVersion?.landscapeId)!
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentTeam () {
    return this.teamModule.teams.find(o => o.id === this.currentTeamId)
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationPermission () {
    return this.currentVersionId === 'latest' ? this.organizationModule.organizationPermission(this.currentOrganization) : 'read'
  }

  get loading () {
    return (
      this.versionModule.travelling ||
      !this.landscapeModule.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      this.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId ||
      this.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId ||
      (!this.modelModule.objectsSubscriptionStatus.success && !this.modelModule.objectsSubscriptionStatus.loadingInfo.reconnect) ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.domainModule.domainsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.domainModule.domainsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get socketError () {
    return this.socketModule.socketSubscriptionActive ? undefined : this.socketModule.socketSubscriptionError || this.socketModule.socketError
  }

  get teamsListStatus () {
    return this.teamModule.teamsListStatus
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get teams () {
    return this.teamModule.teams
  }

  get teamObjects (): IObjectGridItem[] {
    return Object
      .values(this.modelModule.objects)
      .filter(o => this.currentTeamId && o.teamIds.includes(this.currentTeamId) && (!this.currentDomain || this.currentDomain.id === o.domainId))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(o => ({
        object: {
          ...o,
          external: o.external || this.modelModule.objects[o.id]?.parentIds.some(e => this.modelModule.objects[e]?.external)
        }
      }))
  }

  get analyticsEvent (): Parameters<typeof analytics.teamScreen.track>[1] | undefined {
    if (this.currentLandscape && this.teamsListStatus.successInfo.organizationId === this.currentOrganizationId && (!this.teamModule.teams.length || this.currentTeam)) {
      return {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        teamId: this.currentTeam ? [this.currentTeam.id] : []
      }
    }
  }

  @Watch('currentTeamId')
  onCurrenTeamIdChanged () {
    if (this.currentLandscapeId) {
      this.setCurrentTeam(this.currentLandscapeId)
    }
  }

  @Watch('teamsListStatus.success')
  onTeamListStatusSuccessChanged (success: boolean) {
    if (success && !this.currentTeam && this.currentLandscapeId) {
      this.setCurrentTeam(this.currentLandscapeId)
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId?: string) {
    if (currentOrganizationId && this.teamsListStatus.loadingInfo.organizationId !== currentOrganizationId && this.teamsListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.teamModule.teamsList(currentOrganizationId)
    }

    if (currentOrganizationId && this.organizationUsersListStatus.loadingInfo.organizationId !== currentOrganizationId && this.organizationUsersListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.organizationModule.organizationUsersList(currentOrganizationId)
    }
  }

  @Watch('analyticsEvent')
  onAnalyticsEventChanged (analyticsEvent?: Parameters<typeof analytics.teamScreen.track>[1], prevAnalyticsEvent?: Parameters<typeof analytics.teamScreen.track>[1]) {
    if (analyticsEvent && !isEqual(analyticsEvent, prevAnalyticsEvent)) {
      analytics.teamScreen.track(this, analyticsEvent)
    }
  }

  mounted () {
    if (this.currentOrganizationId && this.teamsListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.teamModule.teamsList(this.currentOrganizationId)
    } else if (!this.currentTeam && this.currentLandscapeId) {
      this.setCurrentTeam(this.currentLandscapeId)
    }

    if (this.currentOrganizationId && this.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUsersList(this.currentOrganizationId)
    }

    if (this.analyticsEvent) {
      analytics.teamScreen.track(this, this.analyticsEvent)
    }
  }

  setCurrentTeam (landscapeId: string) {
    this.$router.replace({
      name: 'teams',
      params: {
        landscapeId,
        versionId: this.currentVersionId
      },
      query: {
        domain: this.currentDomainHandleId || undefined,
        team: this.currentTeamId || this.teamModule.teams[0]?.id
      }
    })
  }
}


import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import AccuracyScoreIndicator from '@/modules/accuracy/components/score-indicator.vue'
import { DiagramModule } from '@/modules/diagram/store'
import ModelObjectPreview from '@/modules/model/components/objects/object-preview.vue'

export interface IObjectGridItem {
  object: ModelObject
}

@Component({
  components: {
    AccuracyScoreIndicator,
    Menu,
    ModelObjectPreview
  },
  name: 'TeamObjectGridItem'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)

  @Prop() readonly item!: IObjectGridItem

  imageLoaded = false
  imageError = false

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get toScoreIndicator (): Location | undefined {
    const firstDiagram = Object.values(this.item.object.diagrams).find(o => o)
    const invalidLinks = Object.values(this.item.object.links).some(o => 'status' in o && o.status === 'invalid')
    if (invalidLinks) {
      if (this.item.object.type === 'system' || this.item.object.type === 'app') {
        return {
          name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: {
            landscapeId: this.currentLandscapeId,
            versionid: this.currentVersionId
          },
          query: {
            model: this.item.object.handleId,
            object: this.item.object.id
          }
        }
      } else if (firstDiagram) {
        const diagram = this.diagramModule.diagrams[firstDiagram.id]
        return {
          name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: {
            landscapeId: this.currentLandscapeId,
            versionid: this.currentVersionId
          },
          query: {
            diagram: diagram.handleId,
            object: firstDiagram.objectId
          }
        }
      }
    }
  }
}

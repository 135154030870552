
import { CodeRepoLink, ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import openExternalLink from '@/helpers/open-external-link'
import * as sort from '@/helpers/sort'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'ModelObjectPreview'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly object!: ModelObject

  @Prop({ default: 256 }) readonly width?: number | string
  @Prop({ default: 128 }) readonly height?: number | string
  @Prop() readonly minWidth?: number | string
  @Prop() readonly minHeight?: number | string
  @Prop() readonly maxWidth?: number | string
  @Prop() readonly maxHeight?: number | string
  @Prop({ default: false, type: Boolean }) readonly basic!: boolean

  hoverZoom = false

  iconUrlForTheme = iconUrlForTheme

  get version () {
    return this.versionModule.versions.find(o => o.id === this.object.versionId)
  }

  get versionLatest () {
    return this.version?.tags.includes('latest')
  }

  get landscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.version?.landscapeId)
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.landscape)
  }

  get type () {
    return this.object.external ? `[External ${this.object.type}]` : `[${this.object.type.slice(0, 1).toUpperCase() + this.object.type.slice(1)}]`
  }

  get name () {
    return this.object.name || `${this.object.type.slice(0, 1).toUpperCase() + this.object.type.slice(1)}`
  }

  get nameColor () {
    return this.object.name ? 'white--text' : 'grey--text text--lighten-3'
  }

  get nameFontSize () {
    if (this.object.name.length > 60) {
      return 14
    } else if (this.object.name.length > 30) {
      return 16
    } else {
      return 20
    }
  }

  get links () {
    return Object.values(this.object.links).sort(sort.index)
  }

  get linksInvalid () {
    return Object.values(this.links).filter(o => 'status' in o && o.status === 'invalid')
  }

  get primaryLink () {
    return this.links.find((o): o is CodeRepoLink => 'status' in o && o.status === 'invalid') || this.links.find((o): o is CodeRepoLink => 'status' in o && o.status === 'valid') || this.links.find(o => o)
  }

  get toZoom (): Location | undefined {
    if (this.landscape && (this.object.type === 'system' || this.object.type === 'app') && (this.landscapePermission !== 'read' || this.object.childDiagramIds.length)) {
      return {
        name: this.versionLatest ? 'editor-diagram' : 'version-diagram',
        params: {
          landscapeId: this.landscape.id,
          versionId: 'latest'
        },
        query: {
          model: this.object.handleId,
          object: this.object.id
        }
      }
    }
  }

  get toInstance (): Location | undefined {
    const firstDiagram = Object.values(this.object.diagrams).find(o => o)
    if (this.landscape && firstDiagram && !this.basic) {
      const diagram = this.diagramModule.diagrams[firstDiagram.id]
      return {
        name: this.versionLatest ? 'editor-diagram' : 'version-diagram',
        params: {
          landscapeId: this.landscape.id,
          versionId: 'latest'
        },
        query: {
          diagram: diagram.handleId,
          object: firstDiagram.objectId
        }
      }
    }
  }

  openLink () {
    if (!this.landscape) {
      return
    }

    if (this.links.length === 1 && (this.links[0].type === 'url' || this.links[0].status === 'valid')) {
      openExternalLink(this.links[0].url)

      analytics.modelObjectLinkOpen.track(this, {
        landscapeId: [this.landscape.id],
        modelObjectLinkType: this.links[0].type,
        organizationId: [this.landscape.organizationId]
      })
    } else {
      this.$router.push({
        name: 'model-objects',
        query: {
          focus: this.object.handleId,
          object: this.object.handleId
        }
      })
    }
  }
}

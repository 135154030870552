
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ObjectGridItem, { IObjectGridItem } from './object-grid-item.vue'

@Component({
  components: {
    ObjectGridItem
  },
  name: 'TeamObjectGrid'
})
export default class extends Vue {
  @Prop() readonly items!: IObjectGridItem[]
}

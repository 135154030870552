import { render, staticRenderFns } from "./object-grid-item.vue?vue&type=template&id=3d805b0c"
import script from "./object-grid-item.vue?vue&type=script&lang=ts"
export * from "./object-grid-item.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.22_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_gnkt6mqmu7ofl6lgbdc2xoqxvi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports